<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import   helperFile   from '../../../helpers/helper';
export default {
  page: {
    title: "Principal Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Principal Profile",
      items: [
        {
          text: "Principal Profile",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      principleDetails:"",
      subCorporatesDataArr:[],
      // menuAccessList:[
      //     {'title':'Cafeteria','enum':'cafeteria','displayName':'Cafeteria','displayDisabled':true},
      //     {'title':'Food Court','enum':'food_court','displayName':'Food Court','displayDisabled':true},
      //     {'title':'Corporate Meal','enum':'corporate_meal','displayName':'Corporate Meal','displayDisabled':true},
      //     {'title':'Team Meeting','enum':'team_meeting','displayName':'Team Meeting','displayDisabled':true},
      //     {'title':'Event / Party Meal','enum':'events_party','displayName':'Event / Party Meal','displayDisabled':true},
      //     {'title':'Nearby Restaurant','enum':'restaurants_nearby','displayName':'Nearby Restaurant','displayDisabled':true},
      // ],
      displayName:[],
      finalStepNo:[],
      checkBox:[],
      menuAccessEnum:[],
      principleBranchID:"",
      restBranchID:"",
      cafeteriaList:[],
      foodCourtList:[],
      nameDisabled: true,
      menuTabTypeArr:[],
      adminArr:[],
      foodCourtID:"",
      branches:[],
      cityArr:[],
      city:"",
      branchID:"",
      countryArr : [],
      country :"",
  
    };
  },

  //   validations: {
  //   posts: {
  //     restName: {
  //       required,
  //     },
  //     foodType: {
  //       required,
  //     },
  //     cuisineType:{
  //       required,
  //     },
  //     companyID:{
  //       required,
  //     },
  


  //   },
  // },
  methods:{
    deleteAdmin(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you really want to delete this principle user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!"
      }).then((result)=> {
        if (result.isConfirmed) {
          this.axios
              .post(this.$loggedRole+
                  "/delete-principle-admin",
                  { id: id },
              )
              .then((result) => {
                this.$swal({
                  text: result.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                }).then(()=>{
                  location.reload();
                });
              })
              .catch(error => {
                this.loading = false;
                this.$swal({
                  text: error.response.data.message,
                  icon: "error",
                });
              });
        }
      });


    },
    getPrincipleCountry(){
        this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {principleID:this.$storageData.profile.principleID}
        )
        .then((response) => {
                this.countryArr = response.data.data;
                this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getPrincipleCities();
                
        });
    },
  getPrincipleCities(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {principleID:this.$storageData.profile.principleID,
          country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                this.getPrincipleBranchByPrincipleID();
                
        });
  },
getPrincipleBranchByPrincipleID(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID",
          {
              principleID:this.$storageData.profile.principleID,
              city: (this.city) ? this.city.city : ""
          }
        )
        .then((response) => {
            this.branches = response.data.data;
            this.branchID = this.branches[0];
            this.principleBranchID = this.branchID.principleBranchID;
            this.getPrincipleCorporateBranches(this.principleBranchID);
        
        
        });
},
    principleBranchLogin(){
         this.principleBranchID = this.$storageData.profile.principleBranchID;
         //this.getPrincipleRestaurants();
         this.getPrincipleBranchDetails(this.principleBranchID);
    },
    getPrincipleCorporateBranches(principleBranchID){
             this.axios
            .post(
            "getPrincipleCorporateBranches", {
                    principleBranchID: principleBranchID,
                  
                }
            )
            .then((response) => {
                     this.corpArr = response.data.data;
                     this.corpBranch = (this.corpArr) ? this.corpArr[0] : [];
                     this.getPrincipleBranchDetails(this.principleBranchID);
                     //this.principleCorporateStatistics();
            });
    },
    addMenuAccess(){
       if(this.checkBox[0]==true && this.restBranchID.restBranchID==undefined){
          alert("Please Select Cafeteria");
          return;
        }
         if(this.checkBox[1]==true && this.foodCourtID.foodCourtID==undefined){
          alert("Please Select Food Court");
          return;
        }
        this.$root.$emit("bv::hide::modal", "modal-standard");

         let formData = new FormData();  
        formData.append('enumArr',JSON.stringify(this.enumArr));
        formData.append('checkBox', JSON.stringify(this.checkBox));
        formData.append('displayName', JSON.stringify(this.displayName));
        formData.append('stepNo',JSON.stringify(this.finalStepNo));
        formData.append('restBranchID',this.restBranchID.restBranchID);
        formData.append('foodCourtID',this.foodCourtID.foodCourtID);
        formData.append('principleBranchID', this.principleBranchID);

         this.axios
        .post(this.$loggedRole+"/addPrincipleMenuAccess", formData )
        .then((response) => {
          //this.principleDetails = response.data.data;
          this.menuTabTypeArr = response.data.data;
          
        });
    },
    onchangeBranch(){
        this.principleBranchID = this.branchID.principleBranchID;
        this.getPrincipleBranchDetails(this.principleBranchID);
    },
    getPrincipleBranchDetails(principleBranchID){
         this.axios
        .post(this.$loggedRole+"/principleProfileInfo", {'principleBranchID':principleBranchID} )
        .then((response) => {
          this.principleDetails = response.data.data;
          this.subCorporatesDataArr = response.data.data.subCorporates;
         // this.corpMealAccessArr = response.data.data.corpMealAccessArr;
          this.menuTabTypeArr = response.data.data.corpMealAccessArr;
          this.checkBox = response.data.data.menuTabTypeArr;
          this.displayName = response.data.data.finalDisplayName;
          this.finalStepNo = response.data.data.finalStepNo;
          this.enumArr = response.data.data.enumArr;
          this.restBranchID = response.data.data.restData;
          this.adminArr = response.data.data.adminArr;

          this.nearbyCafeteria = helperFile.getNearByRestaurantOrCafe;
          this.nearbyCafeteria(this.principleDetails.latitude,this.principleDetails.longitude,2); // flag =>restaurant only, 2=>cafeteria
          
        });
    },
  // isChecked(index){
   
  //   this.menuAccessList[index].displayDisabled = !this.menuAccessList[index].displayDisabled;
  // },
   addUserPage(){
        this.$router.push({name: 'corporateUsersForm', params: { id: sessionStorage.getItem('principleBranchID') }});
    },
        getFoodCourtList(){
       this.axios.post(this.$loggedRole+"/getFoodCourtList")
          .then((result)=>{
            this.foodCourtList = result.data.data;
              
        });
      
    }

  },
  created(){
            this.id = this.$route.params.id;
            if(this.id){
              sessionStorage.setItem('data',this.id);// Save to sessionStorage
            }
            
  },
mounted(){
    if(this.$storageData.login_type!=4){ //not principle login
      this.principleBranchID = sessionStorage.getItem('data');
    }else{
      this.principleBranchID = this.$storageData.profile.principleBranchID;
    }
    // this.getPrincipleBranchDetails(this.principleBranchID);
    // this.getPrincipleCities();
     (this.$storageData.profile.empTypeID==7) ? this.getPrincipleCountry() : this.getPrincipleBranchDetails(this.principleBranchID);//this.principleBranchLogin();
    this.getFoodCourtList();
  },

  middleware: "authentication",
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />

        <!--- DROP DOWNS-->
        <div class="row">
        <!-- For owner and branh login -->
        <div class="col-md-2" style="position: absolute;right: 51%;top: 75px;"
              v-if="this.$storageData.profile.empTypeID==7">

                          <label>Select Country </label>
                              <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                                @input="getPrincipleCities();" :show-labels="false"></multiselect>
          </div>
        <!-- Cities List-->
        <div class="col-md-2" style="position: absolute; right: 37.5%; top: 75px; width: 15%;" 
         v-if="this.$storageData.profile.empTypeID==7">

                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="getPrincipleBranchByPrincipleID();" :show-labels="false"></multiselect>
         </div>
        <!--END Cities List-->
         <div class="col-md-3" style="position: absolute;right: 19%;width: 20%; top: 75px;" 
         v-if="this.$storageData.profile.empTypeID==7">

                     <label>Select Principle Branch </label>
                        <multiselect v-model="branchID" :options="branches" track-by="principleBranchID" label="principleBranchName"
                          @input="onchangeBranch();" :show-labels="false"></multiselect>
         </div>
     </div>
        <!-- DROP DOWNS END--->

        <div class="col-lg-12" style="margin-top: 3%;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <!-- <div class="col-md-1">  
                            <img :src="principleDetails.image ? principleDetails.image : $placeholder" class="profile-logo mb-2">
                        </div>
                        <div class="col-md-10">
                                <h4 class="card-title" style="margin-top: 17px;">
                                    {{principleDetails.principleName}}</h4>

                                <p class="card-title-desc">
                                    {{principleDetails.branchName}}
                                </p>
                        </div> -->
                        <div class="col-md-11">
                        <img :src="principleDetails.image ? principleDetails.image : $placeholder" class="profile-logo mb-2"  alt="thumb" style="width:auto; height:60px;float: left;margin-right: 10px;">
                             <h4 class="card-title" style="margin-top: 17px;">
                                     {{principleDetails.principleName}}</h4>

                                <p class="card-title-desc">
                                    {{principleDetails.branchName}}
                                </p>
                        </div>
                        <!-- <div class="col-md-1">
                            <button class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light" 
                            style="position: absolute;right: 2%;" @click="addUserPage();"> Add User <i class="uil-user-plus"></i></button>
                        </div> -->

                    </div>
                     
                        
                        <b-tabs content-class="p-3 text-muted">
                        <b-tab active class="border-0">
                            <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Menu Access</span>
                            </template>
                            <div class="row">
                                <div class="table-responsive col-md-8">
                                    <table class="table table-striped mb-0" style="margin-top:15px;">
                                        <thead>
                                          <tr>
                                          <td>Status</td>
                                          <td>Menu Access</td>
                                          <td>Display Name</td>
                                          <td>Restaurant / Food Court</td>

                                          </tr>
                                        </thead>
                                        <tbody v-for="(data) in menuTabTypeArr" :key="data.id">
                                            <tr>
                                                <td v-if="data.menuTabType" class="text-success">Active</td>
                                                <td v-else>-</td>
                                                <td>{{data.menuAccessName}}</td>
                                                <td>{{data.displayName}}</td>
                                                <td v-if="data.menuTabType=='cafeteria'">{{data.restaurantName}}</td>
                                                  <td v-else>{{data.foodCourtName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <!-- Edit Access only for super Admin -->
                                    <div class="col-md-1">   
                                        <button v-if="this.$storageData.login_type==1" class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light mt-3"
                                         v-b-modal.modal-standard>Edit  <i class="uil-edit-alt"></i></button>
                                    </div>
                            </div>
                        </b-tab>
                        <b-tab title="Basic Details">
                            <div class="table-responsive col-md-8">
                                    <table class="table table-striped mb-0" style="margin-top:15px;">
                                       
                                        <tbody>
                                            <tr>
                                               <td>Address</td>
                                                <td>{{principleDetails.address}}</td>
                                            
                                            </tr>
                                          
                                        </tbody>
                                    </table>
                              </div>
                                 <h6 class="mt-3"> Sub-Corporates </h6>
                                <div class="table-responsive mb-0">
                                  <table class="table table-striped mb-0" style="max-width: 600px;margin-top:15px;">
                                        <thead>
                                          <tr>
                                          <td>Corporate Name</td>
                                          <td>Location</td>
                                          <td>Address</td>
                                          
                                          
                                          </tr>
                                        </thead>
                                        <tbody v-for="(data) in subCorporatesDataArr" :key="data.id">
                                            <tr>
                                                <td>{{data.corporateName}}</td>
                                                <td>{{data.branchName}}</td>
                                                <td>{{data.address}}</td>
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                        
                                    </div>
                        </b-tab>
                        <b-tab title="Admins">
                          <div class="row">
                             <div class="table-responsive col-md-10">
                                    <table class="table table-striped mb-0" style="margin-top:15px;">
                                        <thead>
                                          <tr>
                                          <td>Name</td>
                                          <td>Role</td>
                                          <td>Email</td>
                                          <td>Mobile No</td>
                                            <td>Action</td>
                                          </tr>
                                        </thead>
                                        <tbody v-for="(data) in adminArr" :key="data.id">
                                            <tr>
                                                <td>{{data.adminName}}</td>
                                          
                                                <td>{{data.role}}</td>
                                                <td>{{data.adminEmailID}}</td>
                                                <td>{{data.adminMobileNO}}</td>
                                              <td>
                                                <router-link
                                                    :to="{
                       name: 'principleAdminForm',
                        params: { principle_branch: principleBranchID,
                        principle_id:principleDetails.principleID,
                        admin_id : data.id,
                        type:'edit'}
                      }"
                                                    v-if="$can('edit principal admin')"
                                                >
                                                  <button type="button" class="btn btn-outline-warning btn-sm">
                                                    <i
                                                        class="uil uil-edit-alt"
                                                        title="Edit"
                                                        style="font-size: 19px"
                                                    ></i>

                                                  </button>
                                                </router-link>

                                                &nbsp;
                                                <button type="button"
                                                        v-if="$can('delete principal admin')"
                                                        class="btn btn-outline-danger btn-sm" @click="deleteAdmin(data.id)">
                                                  <i
                                                      class="uil uil-trash"
                                                      title="Delete"
                                                      style="font-size: 19px"
                                                  ></i>
                                                </button>
                                              </td>
                                            </tr>
                                        </tbody>
                                    </table>
                              </div>
                          <div v-if="$can('create principal admin')" class="col-md-2">
                            <router-link
                                :to="{
                       name: 'principleAdminForm',
                        params: { principle_branch: principleBranchID,
                        principle_id:principleDetails.principleID,
                        admin_id : 0,
                        type:'create'}
                      }"
                            >
                              <button
                                  class="btn btn-themeYellow w-md waves-effect waves-light mt-3"
                              >
                                Add Admin
                              </button>
                            </router-link>
                          </div>
                          </div>

                        </b-tab>
                    </b-tabs>

                    <b-modal
                        id="modal-standard"
                        size="lg"
                        title="Menu Access"
                        title-class="font-16"
                        >
                
                <div class="row" v-for="(data, index) in menuTabTypeArr"
                        :key="data.id">
                            <div class="row" style="margin-bottom: 7px">
                            <div class="col-md-2 mt-2">
                                    <b-form-checkbox
                                        v-model="checkBox[index]"
                                        class="mb-3"
                                        checked
                                        plain
                                       
                                        >{{data.menuAccessName}}</b-form-checkbox
                                    >
                                    
                            </div>
                            <div class="col-md-3">
                              <label> Display Name: </label>
                                    <input type="text"  class="form-control" id="formrow-corpCode-input" :disabled="data.displayDisabled" name="corporateCode" placeholder="Enter display name" v-model="displayName[index]">
                            </div>
                            <div class="col-md-3">
                              <label> Display Sequence: </label>
                                    <input type="text"  class="form-control" placeholder="Enter display Sequence" v-model="finalStepNo[index]">
                            </div>

                             <div v-if="index==0" class="col-md-4">
                              <label> Select Cafeteria </label>
                                 <multiselect v-model="restBranchID" :options="cafeteriaList"  :show-labels="false" track-by="restBranchID" label="restaurantName"
                                    :class="{
                                'is-invalid': submitted && $v.restBranchID.$error,
                                 }"></multiselect>
                                     <div
                                  v-if="submitted && $v.restBranchID.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.restBranchID.required"
                                    >This value is required.</span
                                  >
                                </div>
                            </div>
                            <div v-if="index==1" class="col-md-4">
                              <label> Select Food Court </label>
                                 <multiselect v-model="foodCourtID" :options="foodCourtList"  :show-labels="false"
                                  track-by="foodCourtID" label="foodCourtName" :class="{
                                'is-invalid': submitted && $v.foodCourtID.$error,
                                 }"
                                    ></multiselect>

                                      <div
                                  v-if="submitted && $v.foodCourtID.$error"
                                  class="invalid-feedback"
                                >
                                  <span v-if="!$v.foodCourtID.required"
                                    >This value is required.</span
                                  >
                                </div>
                            </div>
                            
                          
                            <hr style="height: 1px;color: #b1b1b1;margin: 5px;">
                        </div>
                </div>
                  <template #modal-footer style="border-top:none !important;">
                    <div class="w-100">
                    
                      <b-button
                        variant="primary"
                        size="sm"
                        
                        @click="addMenuAccess"
                        style="float:right;"
                      >
                        Save
                      </b-button>
                    </div>
                  </template>

                    </b-modal>
                    
                    <!-- <div class =" row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                    <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                  </div> -->

                </div>
               
            </div>
        </div>
</Layout>
</template>

<style scoped>
.profile-logo{
  height: 71px;
    width: 71px;
    background-color: #f6f6f6;
    padding: 3px;
    object-fit: contain;
}
.table-striped td{
  border: 1px solid gainsboro !important;
}
</style>


